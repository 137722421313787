import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { useEffect, useState } from 'react';

type TransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
};

type QueryType = ParsedUrlQueryInput & {
  expanded?: string | string[];
  selected?: string;
  offset?: number;
  code?: string;
  tab?: string;
  subTab?: string;
};

const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false);
  const handleRouteChange = () => {
    setOnChanging(false);
  };
  const router = useRouter();

  // safePush is used to avoid route pushing errors when users click multiple times or
  // when the network is slow: "Error: Abort fetching component for route"
  const safePush = (
    path: string | null,
    query?: QueryType | null,
    options?: TransitionOptions | null
  ) => {
    if (onChanging) {
      return;
    }
    router.push({ pathname: path, query }, undefined, { ...options });
  };

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setOnChanging(true);
    });

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeError', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', handleRouteChange);
    };
  }, [router, setOnChanging]);
  return { safePush };
};

export default useSafePush;
