import media from '@/modules/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  max-width: 540px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
export const MainWrapper = styled.div`
  padding-top: 64px;
`;
export const LoginFormWrapper = styled.div`
  padding-top: 32px;
`;

export const CallToLogin = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.font.xxl};
  color: #15171f;
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 104px;
  text-align: center;
`;

export const RememberAndForgotWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space.xxl};

  ${media.tabletLandscape} {
    flex-direction: column;
    gap: ${(props) => props.theme.space.lg};
    margin-bottom: ${(props) => props.theme.space.sm};
  }
`;

export const ForgotPassword = styled.p`
  color: ${(props) => props.theme.color.primary700};
  margin-top: ${(props) => props.theme.space.sm};
  cursor: pointer;
`;

export const ValidationErrorMessage = styled.p`
  color: ${(props) => props.theme.color.red};
  display: flex;
  justify-content: center;
`;

export const CallForSignUP = styled.div`
  display: flex;
  justify-content: center;
  gap: ${(props) => props.theme.space.md};
`;

export const NoAccountText = styled.p``;

export const SignUpText = styled.p`
  color: ${(props) => props.theme.color.primary700};
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
