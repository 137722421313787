import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: fit-content;
  min-height: 100%;
  background-color: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.space.xl};
  padding-top: 0px;
`;
