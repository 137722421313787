import styled from 'styled-components';

import media from '@/modules/media';

import { registrationHeaderStyles } from '@/styles/components/RegistrationHeaderStyles';
import { Button } from '@chakra-ui/react';
import Icon from '../Icon/Icon';

export const Wrapper = styled.div<{ $isPageScrolled: boolean }>`
  max-width: calc(
    100% - ${(props) => props.theme.loginRegisterDrawerSize.desktop}
  );
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: ${(props) => `${props.theme.space.lg}  ${props.theme.space.xxxl}`};
  ${registrationHeaderStyles};
  ${media.tablet} {
    padding: ${(props) => `${props.theme.space.lg}  ${props.theme.space.xxl}`};
  }

  ${media.tabletLandscape} {
    padding: ${(props) => `0 ${props.theme.space.lg}`};
    max-width: 100%;
  }
`;
export const NoOfStudentsButtonWrapper = styled(Button)`
  padding: 8px 16px !important;
  gap: 8px !important;
  background: transparent !important;
  color: ${(props) => props.theme.color.gray900} !important;
  border: 2px solid #f9f4fd;
  background: linear-gradient(135deg, #fff 0%, #f9f4fd 100%);
`;
export const ButtonIconGradientText = styled.div`
  font-weight: 900;
  font-size: ${(props) => props.theme.font.md};
  line-height: 18px;
  background: linear-gradient(90deg, #882bd6 0%, #0a47a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;

export const Span = styled.span`
  font-size: 32px;
`;

export const LanguageSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LanguageSelectionTitle = styled.p`
  font-size: ${(props) => props.theme.font.lg};
  color: ${(props) => props.theme.color.gray600};
  margin-right: ${(props) => props.theme.space.lg};
  ${media.tablet} {
    display: none;
  }
`;

export const CountryListDivider = styled.div`
  border-bottom: 1px solid rgba(222, 221, 227, 0.4);
`;

export const CountriesDropDown = styled.div`
  position: relative;
  cursor: pointer;
`;

export const FlagAndIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const CountryOptions = styled.div`
  position: absolute;
  width: 224px;
  box-shadow: 0px 0px 8px -2px rgba(144, 143, 163, 0.15);
  right: 0px;
  top: 40px;
  background-color: white;
  border-radius: 16px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 24px;
  height: 64px;
`;

export const FlagDropDownIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;
