import { ReactNode } from 'react';

import RegistrationHeader from '../RegistrationHeader/RegistrationHeader';
import { Wrapper } from './RegistrationLayout.styles';

type RegistrationLayoutProps = {
  children: ReactNode;
  onClick?: () => void;
};

const RegistrationLayout = ({
  children,
  onClick,
}: RegistrationLayoutProps): JSX.Element => {
  return (
    <Wrapper>
      <RegistrationHeader onClick={onClick} />

      {children}
    </Wrapper>
  );
};

export default RegistrationLayout;
