import { requestWithoutAccessToken } from '@/modules/apiRequests/userRequest';
import { useMutation } from '@tanstack/react-query';

type CredentialsType = {
  username: string;
  password: string;
  role: string; // UserType['role'] | 'learner,student';
};

export type ErrorResponseType = {
  errorMessage: string;
  errorType: string;
  path: string;
  statusCode: number;
};

class CustomError extends Error {
  response: ErrorResponseType;

  constructor(message: string, response: ErrorResponseType) {
    super(message);
    this.name = 'CustomError';
    this.response = { ...response };
  }
}

export const useAuthLoginMutation = () =>
  useMutation(async ({ username, password, role }: CredentialsType) => {
    const response = await requestWithoutAccessToken(
      `auth/login?role=${role}`,
      {
        username,
        password,
      },
      'POST'
    );

    if (response.errorMessage) {
      if (role === 'teacher,school-admin' && response.statusCode === 400) {
        throw new CustomError('Wonde Error', response);
      } else {
        throw new CustomError(
          'Email/Username or password is incorrect. Please try again',
          response
        );
      }
    }

    return response;
  });
