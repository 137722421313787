import {
  BgElement,
  IconWelcome,
  IconWelcomeWrapper,
  MessageInner,
  WelcomeText,
  Wrapper,
} from './DakotaDrawerRegistration.styles';

const DakotaDrawerRegistration = () => {
  return (
    <Wrapper>
      <BgElement type="notebookBG" />
      <MessageInner>
        <IconWelcomeWrapper>
          <IconWelcome type="welcomeIcons" />
        </IconWelcomeWrapper>
        <WelcomeText>
          Hello, Welcome to Study Hall. <br /> I am your AI-powered reading,
          writing and exam coach. I am here to make sure you achieve your goals.
        </WelcomeText>
      </MessageInner>
    </Wrapper>
  );
};

export default DakotaDrawerRegistration;
