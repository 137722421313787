import { fetchNoOfStudentsAnonymous } from '@/store/no-of-students/queries';
import Icon from '../Icon/Icon';
import {
  ButtonIconGradientText,
  NoOfStudentsButtonWrapper,
} from './RegistrationNoOfStudent.styles';

const RegistrationNoOfStudent = () => {
  const { data, isFetched } = fetchNoOfStudentsAnonymous();

  return (
    <div>
      {isFetched && (
        <NoOfStudentsButtonWrapper>
          <Icon type="communityIcon" size="smd" />
          <span>{data.students?.toLocaleString()} Studying</span>
        </NoOfStudentsButtonWrapper>
      )}
    </div>
  );
};

export default RegistrationNoOfStudent;
