import { safeParseWithLogging } from '../helpers';
import { PROFILE_NO_OF_STUDENTS_URL } from '../paths';
import { useBaseQuery, useBaseQueryAnonymous } from '../utils';
import { NoOfStudentsSchema } from './schemas';
import { NoOfStudentsDto } from './types';

export const fetchNoOfStudents = <T = NoOfStudentsDto>(
  transformFn?: (data: NoOfStudentsDto) => T
) =>
  useBaseQuery<NoOfStudentsDto, T>(
    PROFILE_NO_OF_STUDENTS_URL,
    ['profile', 'no-of-students'],
    {} as NoOfStudentsDto,
    (response) => safeParseWithLogging(NoOfStudentsSchema, response),
    transformFn
  );

export const fetchNoOfStudentsAnonymous = <T = NoOfStudentsDto>(
  transformFn?: (data: NoOfStudentsDto) => T
) =>
  useBaseQueryAnonymous<NoOfStudentsDto, T>(
    PROFILE_NO_OF_STUDENTS_URL,
    ['profile', 'no-of-students'],
    {} as NoOfStudentsDto,
    (response) => safeParseWithLogging(NoOfStudentsSchema, response),
    transformFn
  );
