import { countries } from 'countries-list';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Icon } from '@/components/common';
import { IconTypes } from '@/components/common/Icon/types';
import {
  CountryDataType,
  CountryType,
} from '@/components/common/RegistrationHeader/types';
import { useCountryContext } from '@/contexts/LocalizationContext';

import useScrollPosition from 'hooks/useScrollPosition';
import Logo from '../Logo/Logo';
import {
  ButtonIconGradientText,
  CountriesDropDown,
  CountryListDivider,
  CountryOptions,
  FlagAndIconWrapper,
  FlagDropDownIcon,
  LanguageSelectionTitle,
  LanguageSelectionWrapper,
  NoOfStudentsButtonWrapper,
  Option,
  Wrapper,
} from './RegistrationHeader.styles';
import RegistrationNoOfStudent from '../RegistrationNoOfStudent/RegistrationNoOfStudent';

const { GB, US } = countries;

// This Object is added because EU is a continent and not a country
const EU = {
  name: 'European Union',
  native: '',
  phone: '',
  continent: '',
  capital: '',
  currency: 'EURO',
  languages: ['en'],
  emoji: '🇪🇺',
  emojiU: 'U+1F1EA U+1F1FA',
};

const selectedCountries = [
  { ...GB, codeName: 'GB' },
  { ...US, codeName: 'US' },
  { ...EU, codeName: 'EU' },
];

type RegistrationHeaderType = {
  onClick?: () => void;
};

const RegistrationHeader = ({
  onClick,
}: RegistrationHeaderType): JSX.Element => {
  const { country, setCountryInfo } = useCountryContext();
  const [localizationState, setLocalizationState] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(country);
  const router = useRouter();
  const hideLanguageSelectionTitle =
    router.pathname.includes('login') ||
    router.pathname.includes('reset-password') ||
    router.pathname.includes('forgotPassword') ||
    router.pathname.includes('email-sent-confirmation') ||
    router.pathname.includes('create-new-password');

  const handleCountrySelectionClicked = (countryData: CountryDataType) => {
    const passedCountry = {
      name: countryData.name,
      phone: countryData.phone,
      codeName: countryData.codeName,
    };
    setSelectedCountry(passedCountry);

    setCountryInfo(passedCountry);
  };

  const scrollPosition = useScrollPosition();

  return (
    <Wrapper $isPageScrolled={scrollPosition > 0}>
      <Logo onClick={onClick} />
      <RegistrationNoOfStudent />
      {/* <LanguageSelectionWrapper>
        {!hideLanguageSelectionTitle && (
          <LanguageSelectionTitle>Studying in:</LanguageSelectionTitle>
        )}
        <CountriesDropDown
          onClick={() => setLocalizationState(!localizationState)}
        >
          <FlagAndIconWrapper>
            <Icon
              type={
                `${selectedCountry.codeName.toLowerCase()}Flag` as IconTypes
              }
              size="md"
            />
            <FlagDropDownIcon
              type={localizationState ? 'upArrow' : 'downArrow'}
            />
          </FlagAndIconWrapper>

          {localizationState === true && (
            <CountryOptions>
              {selectedCountries.map((countryData, index) => (
                <>
                  <Option
                    key={`${countryData.name}-${index.toString()}`}
                    onClick={() => handleCountrySelectionClicked(countryData)}
                  >
                    <Icon
                      type={
                        `${countryData.codeName.toLowerCase()}Flag` as IconTypes
                      }
                      size="md"
                    />
                    {`${countryData.name}`}
                  </Option>
                  {index + 1 < selectedCountries.length && (
                    <CountryListDivider />
                  )}
                </>
              ))}
            </CountryOptions>
          )}
        </CountriesDropDown>
      </LanguageSelectionWrapper> */}
    </Wrapper>
  );
};

export default RegistrationHeader;
