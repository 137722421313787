import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const NoOfStudentsButtonWrapper = styled(Button)`
  background: linear-gradient(135deg, #ffffff 0%, #f4f8fe 100%) !important;
  border: 2px solid ${(props) => props.theme.color.blue10} !important;
  border-radius: 16px !important;
  padding: 8px 16px !important;
  gap: 8px !important;
  color: ${(props) => props.theme.color.blue800} !important;
  font-size: ${(props) => props.theme.font.xs} !important;
  font-weight: 900 !important;
`;
export const ButtonIconGradientText = styled.div`
  font-weight: 900;
  font-size: ${(props) => props.theme.font.md};
  line-height: 18px;
  background: linear-gradient(90deg, #882bd6 0%, #0a47a3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;
