import styled from 'styled-components';
import {
  shadowBoxBlueStyles,
  shadowBoxRedStyles,
} from '@/styles/components/ShadowBoxStyles';
import Icon from '../../Icon/Icon';

export const StudyGuideHeaderDakota = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 0 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.color.gray200};
`;
export const Title = styled.div`
  font-size: ${(props) => props.theme.font.lg};
  font-weight: 900;
  line-height: 24px;
`;
export const Wrapper = styled.div`
  /* background-image: url('data:image/svg+xml,<svg width="616" height="900" viewBox="0 0 616 900" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_28102_77641)"><g filter="url(%23filter0_f_28102_77641)"><circle cy="900" r="269" fill="%23F9F4FD"/></g><g filter="url(%23filter1_f_28102_77641)"><circle cx="571" cy="181" r="269" fill="%23EDF3FE"/></g></g><defs><filter id="filter0_f_28102_77641" x="-469" y="431" width="938" height="938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_28102_77641"/></filter><filter id="filter1_f_28102_77641" x="102" y="-288" width="938" height="938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_28102_77641"/></filter><clipPath id="clip0_28102_77641"><rect width="616" height="900" fill="white"/></clipPath></defs></svg>');
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
  padding: 32px;
  color: ${(props) => props.theme.color.gray900};
  border-left: 1px solid ${(props) => props.theme.color.gray200};
  height: 100%;
  border-radius: 0;
  background-color: ${(props) => props.theme.color.white};
`;
export const BgElement = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const MessageInner = styled.div`
  position: relative;
  margin-top: 197px;
  text-align: center;
  padding: 80px 32px 40px;
  box-shadow: 0px 0px 16px 0px #d0d2d84d;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 24px;
`;
export const IconWelcomeWrapper = styled.div`
  position: absolute;
  top: -78px;
  left: 50%;
  transform: translateX(-50%);
`;
export const IconWelcome = styled(Icon)`
  width: 276px;
  height: 136px;
`;
export const WelcomeText = styled.div`
  font-size: ${(props) => props.theme.font.md};
  font-weight: 600;
  line-height: 18px;
  color: ${(props) => props.theme.color.gray900};
`;

export const DakotaBox = styled.div`
  ${shadowBoxRedStyles}
  margin-bottom: 32px;
  padding: 24px;
  position: relative;
  overflow: hidden;
`;
export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: -5px;
`;

export const BoostLearningTitle = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  gap: 8px;
`;
export const BoostLearningData = styled.div``;
export const BoostLearningDakota = styled.div`
  font-size: ${(props) => props.theme.font.md};
  line-height: 18px;
  font-weight: 900;
  margin-bottom: 4px;
  color: ${(props) => props.theme.color.blue800};
`;
export const BoostLearningText = styled.div`
  font-size: ${(props) => props.theme.font.md};
  line-height: 18px;
  color: ${(props) => props.theme.color.gray900};
  margin-bottom: 8px;
`;
