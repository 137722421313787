import { useAuthContext } from '@/contexts/AuthContext';
import { decodeAccessToken } from '@/contexts/AuthContext/helpers';
import { useDiagnosticContext } from '@/contexts/DiagnosticContext/DiagnosticContext';
import { useGlobalContext } from '@/contexts/GlobalContext';
import { useUserProfileContext } from '@/contexts/UserProfileContext';
import { request } from '@/modules/apiRequests/userRequest';
import { clearLocalStorage } from '@/modules/helpers';
import { useAuthLoginMutation } from '@/store/auth/mutations';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export const useAuth = () => {
  const router = useRouter();

  const { dispatch: dispatchAuth } = useAuthContext();

  const { dispatch: dispatchUser } = useUserProfileContext();
  const { dispatch: dispatchDiagnostic } = useDiagnosticContext();
  const { toggleSidebar } = useGlobalContext();
  const queryClient = useQueryClient();

  const {
    error: loginError,
    isLoading: isLoginLoading,
    mutate: mutateLogin,
  } = useAuthLoginMutation();

  const baseLogin = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    const decodedAccessToken = decodeAccessToken(accessToken);
    const payload = {
      userId: decodedAccessToken.sub,
      role: decodedAccessToken.roles[0],
      roles: decodedAccessToken.roles,
      decodedAccessToken,
    };
    dispatchAuth({
      type: 'LOGIN',
      payload,
    });
  };

  const loginWithAccessToken = (
    accessToken: string,
    refreshToken: string,
    options?: { onSuccess?: () => void }
  ) => {
    // TODO: ADD ERROR HANDLING
    baseLogin(accessToken, refreshToken);
    if (options?.onSuccess) options.onSuccess();
  };

  const loginWithCredentials = (
    username: string,
    password: string,
    role: string,
    options?: { onSuccess?: () => void; onError?: (error?: any) => void }
  ) =>
    mutateLogin(
      { username, password, role },
      {
        onSuccess: (data) => {
          baseLogin(data.accessToken, data.refreshToken);
          if (options?.onSuccess) options.onSuccess();
        },
        onError: (error: any) => {
          switch (role) {
            case 'customer':
              break;
            case 'learner,student':
              // The error handling bellow is only for learner role
              if (error.response.statusCode === 402) {
                router.push({
                  pathname: '/payment-not-found',
                  query: {
                    type: 'learner',
                  },
                });
              }
              break;
            case 'teacher':
              break;
            default:
          }
          if (options?.onError) options.onError(error);
        },
      }
    );

  const logout = (redirect = true) => {
    toggleSidebar();
    clearLocalStorage();
    sessionStorage.clear();
    dispatchAuth({ type: 'LOGOUT' });
    dispatchUser({ type: 'INIT', payload: {} as any });
    dispatchDiagnostic({ type: 'RESET' });
    queryClient.clear();
    if (redirect) {
      router.push('/login');
    }
  };

  const getNewTokens = async (options?: { onSuccess?: () => void }) => {
    // TODO: ADD ERROR HANDLING
    const response = await request(
      'auth/refresh',
      localStorage.getItem('refreshToken') || '',
      false,
      'GET'
    );
    const responseParsed = await response.json();
    const { accessToken, refreshToken } = responseParsed;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    const decodedAccessToken = decodeAccessToken(accessToken);
    dispatchAuth({
      type: 'UPDATE_DECODED_ACCESS_TOKEN',
      payload: decodedAccessToken,
    });
    if (options?.onSuccess) options.onSuccess();
  };

  return {
    isLoginLoading,
    loginError,
    loginWithAccessToken,
    loginWithCredentials,
    logout,
    getNewTokens,
  };
};
